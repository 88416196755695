import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal';
import modalCrossBlack from '../../../../assests/modalCross.svg'
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import { Button, styled } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { INVITE_USER_TO_ORG, INVITE_USER_TO_POJECT, LIST_PROJECT_MEMBERS } from '../../../../services';
import STRINGS from '../../../../helpers/staticContentHelper';
import OrgUsers from './orgUsers';
import BackImg from '../../../../assests/back.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { decryptResponse } from '../../../../utils/encryptionDecryption';
import { GET_PROJECTS_LIST } from '../../../../services';
import InviteNewExistingUser from './inviteNewExistingUser';
import { LIST_ORG_MEMBERS } from '../../../../services';
import Chip from '@mui/material/Chip';
import { useParams } from 'react-router-dom';
import { useAlert } from '../../../../utils/alertsProvider';

const buttonStyle = {
    border: "1px solid #E6E8EA",
    borderRadius: "12px",
    color: "black",
    textTransform: 'none',
    "&:hover": {
        border: "1px solid #E6E8EA",
    },
    fontFamily: "Plus Jakarta Sans"
};

export const buttonSecondaryStyle = {
    border: "1px solid #ECF0F3",
    background: '#1B2E31',
    borderRadius: "12px",
    color: "white",
    whiteSpace: 'nowrap',
    textTransform: 'none',
    // backgroundColor: '#ECF0F3',
    "&:hover": {
        border: "1px solid #ECF0F3",
        color: "white",
        background: '#1B2E31',
    },
    "&:disabled": {
        border: "1px solid #ECF0F3",
        color: "#6D6D6D",
        background: '#DBDEE0',
    },
    fontFamily: "Plus Jakarta Sans"
};

export const popUpStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '0.5rem',
};

const fontAndSize = {
    fontFamily: "Plus Jakarta Sans",
    color: 'rgb(27, 46, 49)',
    fontSize: '16px'
}

const ROLES = ['manager', 'estimator']

function extractDomain(email) {
    const regex = /@([\w.-]+)/;
    const match = email.match(regex);
    return match ? match[1] : null;
}

export const handleValidation = (data, adminEmail) => {
    const errorValue = {
        isError: false,
        first_name: '',
        last_name: '',
        user_email: '',
        role: '',
        // project_ids: []
    }
    Object.entries(data).forEach(([key, value]) => {
        // if (key === 'project_ids' && value.length === 0 && data.role !== 'admin') {
        //     errorValue[key] = STRINGS.REQUIRED_FIELD_ERROR_MSG
        //     errorValue.isError = true
        // } else 
        if (!value) {
            errorValue[key] = STRINGS.REQUIRED_FIELD_ERROR_MSG
            errorValue.isError = true
        } else if (key === 'user_email') {
            const userDomain = extractDomain(value)
            const adminDomain = extractDomain(adminEmail)
            const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!re.test(value)) {
                errorValue[key] = STRINGS.ENTER_VALID_EMAIL_ERROR_MSG
                errorValue.isError = true
            } else if (userDomain !== adminDomain) {
                alert(`Please ensure the user invited is from the same domain:  ${adminDomain}`)
                errorValue.isError = true
            }
        }
    });
    return errorValue
}

export const handleInviteUserValidation = (data, adminEmail) => {
    const errorValue = {
        isError: false,
        user_emails: '',
        project_ids: ''
    }
    Object.entries(data).forEach(([key, value]) => {
        if (value.length === 0) {
            errorValue[key] = STRINGS.REQUIRED_FIELD_ERROR_MSG
            errorValue.isError = true
        }
    });
    return errorValue
}

function AddUserModal({ open, setOpen, userData }) {
    const { showAlert } = useAlert();
    const params = useParams();
    const { projectId } = params;
    const [activeTab, setActiveTab] = useState('');
    const [isUserInvitationPending, setIsUserInvitationPending] = useState(false);
    const [projectsList, setProjectsList] = useState([])
    const [usersData, setUsersData] = useState([])
    const [error, setError] = useState({
        isError: false,
        first_name: '',
        last_name: '',
        user_email: '',
        role: '',
        project_ids: []
    })
    const [userFormData, setUserFormData] = useState({
        role: '',
        user_email: '',
        first_name: '',
        last_name: '',
        project_ids: []
    })

    const handleClose = () => {
        setOpen(prev => !prev)
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setUserFormData(prev => {
            return { ...prev, role: value, project_ids: value === 'admin' ? [] : prev.project_ids }
        })
        setError(prev => {
            return { ...prev, role: '' }
        })
    };

    const addUserToOrg = () => {
        const errorData = handleValidation(userFormData, userData.email)
        setError(errorData)
        if (errorData.isError) {
            return
        }
        setIsUserInvitationPending(true)
        INVITE_USER_TO_ORG({ ...userFormData, org_id: userData.org_id }).then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                if (parsedDecryptedData.invite_status) {
                    setIsUserInvitationPending(false)
                    handleClose()
                    showAlert(STRINGS.INVITE_SENT, userFormData.user_email, STRINGS.USER_HAS_BEEN_NOTIFIED_TO_JOIN_ORG)
                } else {
                    alert(JSON.parse(res?.request?.response)?.data || 'something went wrong')
                    setIsUserInvitationPending(false)
                }
            }
        })
    }

    const onChangeFormData = (name, value) => {
        setUserFormData(prev => {
            return { ...prev, [name]: value }
        })
        if (value) {
            setError(prev => {
                return { ...prev, [name]: '' }
            })
        } else {
            setError(prev => {
                return { ...prev, [name]: STRINGS.REQUIRED_FIELD_ERROR_MSG }
            })
        }
    }

    useEffect(() => {
        setUserFormData({
            role: '',
            user_email: '',
            first_name: '',
            last_name: '',
            project_ids: []
        })
        setActiveTab('')
    }, [open])

    const fetchProjectsList = () => {
        GET_PROJECTS_LIST({ created_by: userData.created_by }).then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                if (parsedDecryptedData) {
                    setProjectsList(parsedDecryptedData)
                }
            }

        })
    }

    useEffect(() => {
        if (open) {
            fetchProjectsList();
        }
    }, [open]) //eslint-disable-line

    const handleDelete = (chipToDelete, key) => () => {
        setUserFormData(prev => {
            const filteredData = prev[key].filter((chip) => chip !== chipToDelete)
            return { ...prev, [key]: filteredData }
        })
    };

    const getListOfOrgUsers = (orgId) => {
        LIST_ORG_MEMBERS(orgId).then(res => {
            if (res) {
                const decryptedUsersData = JSON.parse(decryptResponse(res))
                console.log(decryptedUsersData)
                setUsersData(decryptedUsersData)
            }
        })
    }

    const getListOfProjectUsers = (orgId, projectId) => {
        const queryObj = {
            org_id: orgId,
            project_id: projectId,
            existing_project_users: true
        }
        LIST_PROJECT_MEMBERS(queryObj).then(res => {
            if (res) {
                const decryptedUsersData = JSON.parse(decryptResponse(res))
                setUsersData(decryptedUsersData)
            }
        })
    }

    useEffect(() => {
        if (open) {
            if (userData?.org_id && projectId) {
                getListOfProjectUsers(userData.org_id, projectId)
            } else if (userData?.org_id) {
                getListOfOrgUsers(userData.org_id)
            }
        }
    }, [userData.org_id, open, projectId])

    // validation and invite user project
    const [inviteUserError, setInviteUserError] = useState({
        isError: false,
        user_emails: [],
        project_ids: []
    })
    const [inviteUserFormData, setInviteUserFormData] = useState({
        user_emails: [],
        project_ids: []
    })

    const inviteUserToOrg = () => {
        const errorData = handleInviteUserValidation(inviteUserFormData)
        setInviteUserError(errorData)
        if (errorData.isError) {
            return
        }
        setIsUserInvitationPending(true)
        INVITE_USER_TO_POJECT({ ...inviteUserFormData, org_id: userData.org_id }).then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                if (parsedDecryptedData.invite_status) {
                    setIsUserInvitationPending(false)
                    handleClose()
                    const email = inviteUserFormData.user_emails.length === 1 ? inviteUserFormData.user_emails[0] : ''
                    const message = inviteUserFormData.user_emails.length === 1 ? STRINGS.USERS_HAS_BEEN_NOTIFIED_TO_JOIN_PROJECT : STRINGS.USER_HAS_BEEN_NOTIFIED_TO_JOIN_PROJECT
                    showAlert(STRINGS.INVITE_SENT, email, message)
                } else {
                    alert(JSON.parse(res?.request?.response)?.data || 'something went wrong')
                    setIsUserInvitationPending(false)
                }
            }
        })
    }

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
    }));

    function getProjectName(data) {
        const filteredData = projectsList.find(projectData => projectData.project_id === data)
        return filteredData.project_name
    }

    const fetchUserList = () => {
        if (userData?.org_id && projectId) {
            getListOfProjectUsers(userData.org_id, projectId)
        } else if (userData?.org_id) {
            getListOfOrgUsers(userData.org_id)
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...popUpStyle, width: '700px', minHeight: '450px', maxHeight: "90vh", display: 'flex', flexDirection: "column", justifyContent: "space-between", padding: '1rem 1.5rem', overflowY: "scroll" }}>
                {activeTab === 'add-user' ?
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                            <img onClick={() => {
                                setActiveTab('')
                            }} src={BackImg} alt='not-found' width='20px' height='20px' style={{ cursor: "pointer" }} />
                            <p style={{ lineHeight: "0px", ...fontAndSize, textAlign: 'center', fontSize: '20px', fontWeight: '500', flex: 1 }}>
                                {/* {STRINGS.INVITE_USER_TO_TEAM} */}
                                Add user to team
                            </p>
                        </div>
                        <div className='flex-input-box-between' style={{ flex: 1 }}>
                            <div className='flex-input-box' style={{ marginTop: '1rem' }}>
                                <Typography className='input-label' sx={{ ...fontAndSize }}>
                                    {STRINGS.FIRST_NAME}
                                </Typography>
                                <OutlinedInput size='small'
                                    sx={{ ...fontAndSize }}
                                    placeholder="Enter first name "
                                    fullWidth
                                    error={error.isError && error.first_name}
                                    required
                                    value={userFormData.first_name}
                                    className='outline-input-customized'
                                    onChange={(e) => onChangeFormData('first_name', e.target.value)}
                                />
                                {(error.isError && error.first_name) &&
                                    <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                        {error.first_name}
                                    </span>
                                }
                            </div>
                            <div className='flex-input-box' style={{ marginTop: '1rem' }}>
                                <Typography className='input-label' sx={{ ...fontAndSize }}>
                                    {STRINGS.LAST_NAME}
                                </Typography>
                                <OutlinedInput size='small'
                                    sx={{ ...fontAndSize }}
                                    placeholder="Enter last name "
                                    fullWidth
                                    error={error.isError && error.last_name}
                                    required
                                    value={userFormData.last_name}
                                    className='outline-input-customized'
                                    onChange={(e) => onChangeFormData('last_name', e.target.value)}
                                />
                                {(error.isError && error.last_name) &&
                                    <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                        {error.last_name}
                                    </span>
                                }
                            </div>
                        </div>
                        <div className='flex-input-box-between' style={{ marginTop: '1rem' }}>
                            <div className='flex-input-box'>
                                <Typography className='input-label' sx={{ ...fontAndSize }}>
                                    {STRINGS.EMAIL}
                                </Typography>
                                <OutlinedInput size='small'
                                    sx={{ ...fontAndSize }}
                                    placeholder="Enter email "
                                    fullWidth
                                    required
                                    multiline
                                    value={userFormData.user_email}
                                    error={error.isError && error.user_email}
                                    className='outline-input-customized'
                                    onChange={(e) => onChangeFormData('user_email', e.target.value)}
                                />
                                {(error.isError && error.user_email) &&
                                    <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                        {error.user_email}
                                    </span>
                                }
                            </div>
                            <div className='flex-input-box' style={{ marginTop: '1rem' }}>
                                <Typography className='input-label' sx={{ ...fontAndSize }}>
                                    {STRINGS.ROLE}
                                </Typography>
                                <Select
                                    displayEmpty
                                    value={userFormData.role}
                                    onChange={handleChange}
                                    input={<OutlinedInput
                                        fullWidth
                                        required
                                        error={error.isError && error.role}
                                        value={userFormData.role}
                                        size='small'
                                        className='outline-input-customized'
                                    />}
                                    renderValue={(selected) => {
                                        if (ROLES.length === 0 || !userFormData.role) {
                                            return <em>Role</em>;
                                        }
                                        return selected;
                                    }}
                                    MenuProps={[]}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    {/* admin */}
                                    {userData.role === 'admin'
                                        &&
                                        <MenuItem
                                            key='admin'
                                            value='admin'
                                            sx={{ ...fontAndSize }}
                                        >
                                            admin
                                        </MenuItem>}
                                    {ROLES.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            sx={{ ...fontAndSize }}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {(error.isError && error.role) &&
                                    <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                        {error.role}
                                    </span>
                                }
                            </div>
                            {userFormData.role !== 'admin' &&
                                <div className='flex-input-box' style={{ marginTop: '1rem' }}>
                                    <Typography className='input-label' sx={{ ...fontAndSize , color:'gray'}}>
                                        Select Projects (optional)
                                    </Typography>
                                    <Select
                                        disabled={!userFormData.role}
                                        displayEmpty
                                        onChange={(e => {
                                            setUserFormData(prev => {
                                                return { ...prev, project_ids: [...prev.project_ids, e.target.value] }
                                            })
                                            setError(prev => {
                                                return { ...prev, project_ids: '' }
                                            })
                                        })}
                                        input={<OutlinedInput
                                            fullWidth
                                            // required
                                            // error={error.isError && error.project_ids}
                                            size='small'
                                            className='outline-input-customized'
                                        />}
                                        renderValue={() => <em>Select project</em>}
                                        MenuProps={[]}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {projectsList.map((project) => {
                                            if (!userFormData.project_ids.includes(project.project_id)) {
                                                return (
                                                    <MenuItem
                                                        key={project.project_name}
                                                        value={project.project_id}
                                                        sx={{ ...fontAndSize }}
                                                    >
                                                        {project.project_name}
                                                    </MenuItem>
                                                )
                                            }
                                        })}
                                    </Select>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            flexWrap: 'wrap',
                                            listStyle: 'none',
                                            p: 0,
                                            m: 0,
                                        }}
                                        component="ul"
                                    >
                                        {userFormData.project_ids.map((data) => {
                                            let icon;
                                            return (
                                                <ListItem key={data}>
                                                    <Chip
                                                        icon={icon}
                                                        label={getProjectName(data)}
                                                        onDelete={handleDelete(data, 'project_ids')}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                    </Box>
                                    {(error.isError && error.project_ids) &&
                                        <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                            {error.project_ids}
                                        </span>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    :
                    activeTab === 'invite-user' ?
                        <InviteNewExistingUser
                            error={inviteUserError}
                            setError={setInviteUserError}
                            userFormData={inviteUserFormData}
                            setUserFormData={setInviteUserFormData}
                            setActiveTab={setActiveTab}
                            userData={userData}
                            usersData={usersData}
                            getProjectName={getProjectName}
                            projectId={projectId}
                        /> :
                        <>
                            <div className='sub-heading-with-sub-text'>
                                <p style={{ lineHeight: "0px", ...fontAndSize, textAlign: 'center', fontSize: '20px', fontWeight: '500', paddingBottom: "1.5rem" }}>
                                    {STRINGS.USERS}
                                </p>
                            </div>
                            <div style={{ flex: 1, overflow: 'scroll' }}>
                                <OrgUsers projectId={projectId} fetchUserList={fetchUserList} usersData={usersData} userData={userData} />
                            </div>
                        </>
                }
                <div style={{ textAlign: 'end', marginTop: '2rem', display: 'flex', justifyContent: 'end' }}>
                    <Button variant="outlined" size="large"
                        sx={{ ...buttonStyle, marginRight: '1rem', width: '150px' }}
                        onClick={handleClose}
                    >
                        {STRINGS.CANCEL}
                    </Button>
                    {activeTab === 'add-user' ?
                        <>
                            {
                                isUserInvitationPending ?
                                    <div style={{
                                        width: '150px',
                                        color: '#1B2E31',
                                        display: "flex",
                                        justifyContent: 'center',
                                        border: "1px solid #ECF0F3",
                                        borderRadius: "12px",
                                    }}>
                                        <CircularProgress
                                            color="success"
                                            sx={{ color: '#1B2E31' }}
                                        />
                                    </div> :
                                    <Button variant="outlined"
                                        size="large"
                                        sx={{ ...buttonSecondaryStyle, width: '150px' }}
                                        onClick={() => {
                                            if (activeTab === 'add-user') {
                                                addUserToOrg()
                                            } else {
                                                setActiveTab('add-user')
                                            }
                                        }}
                                    >
                                        Add user
                                    </Button>
                            }
                        </>
                        :
                        <>
                            {
                                isUserInvitationPending ?
                                    <div style={{
                                        width: '150px',
                                        color: '#1B2E31',
                                        display: "flex",
                                        justifyContent: 'center',
                                        border: "1px solid #ECF0F3",
                                        borderRadius: "12px",
                                    }}>
                                        <CircularProgress
                                            color="success"
                                            sx={{ color: '#1B2E31' }}
                                        />
                                    </div> :
                                    <Button variant="outlined"
                                        size="large"
                                        sx={{ ...buttonSecondaryStyle, width: '150px' }}
                                        onClick={() => {
                                            if (activeTab === 'invite-user') {
                                                inviteUserToOrg()
                                            } else {
                                                setActiveTab('invite-user')
                                            }
                                        }}
                                    >
                                        {activeTab === 'invite-user' ? 'Sent invite' : STRINGS.INVITE_USER}
                                    </Button>
                            }
                        </>
                    }
                </div>
            </Box>
        </Modal>
    )
}

export default AddUserModal