import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import posthog from "posthog-js"
import { PostHogProvider } from 'posthog-js/react';
import { AlertProvider } from './utils/alertsProvider';
import Alert from './components/alert';
import { FunctionProvider } from './services/triggerLocationHistoryContext';
import { StoreLocationHistoryValuesProvider } from './services/storeMaterialSearchContext';
const root = ReactDOM.createRoot(document.getElementById('root'));

if (typeof window !== 'undefined') {
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
    api_host: "https://us.posthog.com",
    disable_session_recording: true,
  })
}

root.render(
  <React.StrictMode>
    <StoreLocationHistoryValuesProvider>
      <FunctionProvider>
        <AlertProvider>
          <Alert />
          <PostHogProvider client={posthog}>
            <Router>
              <App />
            </Router>
          </PostHogProvider>
        </AlertProvider>
      </FunctionProvider>
    </StoreLocationHistoryValuesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
