import { encryptPayload } from "../utils/encryptionDecryption";
import { getApiEndpoints } from "./api.endpoints";
import { API, API_FOR_FORM, handleApiResponse } from "./axios.config";
import { getBooleanFromLocalStorage } from "./user.services";



export const GET_PROJECTS_LIST = async () => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.get(API_END_POINT.GET_PROJECTS_LIST).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return null
    })
    return res
}

export const ADD_PROJECT = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.ADD_PROJECT, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const UPLOAD_FILES = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.UPLOAD_FILES, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const GET_FILES_LIST = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.GET_FILES_LIST + data, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return null
    });
    return res;
}

export const CREATE_NEW_FOLDER = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.CREATE_NEW_FOLDER, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const FETCH_FOLDER_FILES = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.FETCH_FOLDER_FILES, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return null
    });
    return res;
}

export const INITIAL_FILE_INDEXING = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.INITIAL_PDF_INDEXING, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });

    return res
}

export const INITIAL_MULTI_FILE_INDEXING = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.INITIAL_MULTI_FILE_INDEXING, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });

    return res
}


export const RENAME_PROJECT_NAME = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.put(API_END_POINT.RENAME_PROJECT_NAME, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const RENAME_FILE_NAME = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.put(API_END_POINT.RENAME_FILE_NAME, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const RENAME_FOLDER_NAME = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.put(API_END_POINT.RENAME_FOLDER_NAME, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const DELETE_FILE = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.DELETE_FILE, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const FETCH_PROJECT_HISTORY = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.FETCH_PROJECT_HISTORY + data, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return null
    });
    return res;
}

export const FETCH_FILE_HISTORY = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.FETCH_FILE_HISTORY + data, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return null
    });
    return res;
}

export const FETCH_THREAD_HISTORY = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.FETCH_THREAD_HISTORY + data, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return null
    });
    return res;
}

export const FILE_INDEXING_STATUS = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.FILE_INDEXING_STATUS, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });

    return res
}

export const CELERY_TASK_STATUS = async (data) => {
    // const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.CELERY_TASK_STATUS, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });

    return res
}
export const CELERY_TASK_PENDING_LIST = async (data) => {
    // const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.CELERY_TASK_PENDING_LIST, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });

    return res
}
export const CELERY_PENDING_TASKS_STATUS = async (data) => {
    // const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.CELERY_PENDING_TASKS_STATUS, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });

    return res
}

export const FIND_MATERIAL_PLACE = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.FIND_MATERIAL_PLACE, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });

    return res
}

export const MATERIAL_SEARCH_HISTORY = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.MATERIAL_SEARCH_HISTORY, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const LIST_MATERIAL_SEARCH_HISTORY = async () => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.get(API_END_POINT.LIST_MATERIAL_SEARCH_HISTORY).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}