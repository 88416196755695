import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as SonarWebLogo } from '../../../assests/sonarWebImages/sonarLogo.svg';
import { ReactComponent as HandMenuBurger } from '../../../assests/sonarWebImages/handMenuBurger.svg';
import { ReactComponent as CrossImg } from '../../../assests/sonarWebImages/cross.svg';
import STRINGS from '../../../helpers/staticContentHelper';
import { useNavigate } from 'react-router-dom';
import { LStorage } from '../../../utils/localStorage.Helper';
import { KINDE_LOG_IN, KINDE_SIGN_UP } from '../../../services';
import { decryptResponse } from '../../../utils/encryptionDecryption';

function HeaderComponent({ posthog, showSignInButton = true }) {
  const userData = LStorage.getUserData();
  const [isMenuActive, setIsMenuActive] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (isMenuActive) {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setIsMenuActive(false)
        }
      }
      document.addEventListener("click", handleClickOutside);
    }
  }, [isMenuActive]);

  const navigate = useNavigate();

  const signIn = () => {
    KINDE_LOG_IN().then(res => {
      if (res) {
        const decryptedData = decryptResponse(res)
        const parsedDecryptedData = JSON.parse(decryptedData)
        if (parsedDecryptedData?.url) {
          window.location.href = parsedDecryptedData.url
        }
      }
    })
  }

  return (
    <div className='header-parent'>
      <div className='web-header'>
        <SonarWebLogo onClick={() => {
          navigate('/')
        }} style={{ cursor: 'pointer' }} />
        <div className='web-header-burger-icon'>
          {!showSignInButton ?
            <a href='https://cal.com/sonar/15min' target='_blank' rel="noreferrer">
              <button className='button-secondary-web'
                onClick={() => {
                  posthog.capture('book_demo_clicked')
                }}>
                {STRINGS.BOOK_DEMO}
              </button>
            </a> :
            isMenuActive ?
              <CrossImg className='menu-burger-navar' onClick={() => setIsMenuActive(false)} /> :
              <HandMenuBurger className='menu-burger-navar' onClick={(e) => {
                e.stopPropagation()
                setIsMenuActive(true)
              }} />
          }
          {showSignInButton &&
            <div className={`web-nav-items ${isMenuActive && 'menu-burger-navar-active'}`} ref={wrapperRef}>
              <a className='web-nav-links'
                href='/#feature_section'
                onClick={() => {
                  posthog.capture('features_clicked_header')
                }}>
                {STRINGS.FEATURES}
              </a>
              <a href='/#security_section'
                className='web-nav-links'
                onClick={() => {
                  posthog.capture('security_clicked_header')
                }}>
                {STRINGS.SECURITY}
              </a>
              <a href='https://blog.sonarlabs.ai/'
                className='web-nav-links' onClick={() => {
                  posthog.capture('blog_clicked_header')
                }}>
                {STRINGS.BLOG}
              </a>
              <a href='https://cal.com/sonar/15min' target='_blank' rel="noreferrer">
                <button className='button-secondary-web'
                  onClick={() => {
                    posthog.capture('book_demo_clicked')
                  }}>
                  {STRINGS.BOOK_DEMO}
                </button>
              </a>
              {showSignInButton && !userData && <button className='button-primary-web'
                onClick={() => {
                  // navigate('/login')
                  signIn()
                  posthog.capture('login_clicked_header')
                }}>
                {STRINGS.SIGN_IN_TEXT}
              </button>}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default HeaderComponent;