import React, { useEffect } from 'react';
import './websiteIndex.css';
import { usePostHog } from "posthog-js/react";
import HeroSection from './webComponents/HeroSection';
import HeaderComponent from './webComponents/HeaderComponent';
import ProblemSection from './webComponents/ProblemSection';
import IntegrationSection from './webComponents/IntegrationSection';
import SolutionSection from './webComponents/SolutionSection';
import FooterSection from './webComponents/FooterSection';
import SecuritySection from './webComponents/SecuritySection';

function NewWebPage() {
    const posthog = usePostHog()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className='web-main-container'>
            <HeaderComponent posthog={posthog} />
            <HeroSection posthog={posthog} />
            <ProblemSection />
            <SolutionSection posthog={posthog} />
            <SecuritySection posthog={posthog} />
            <IntegrationSection />
            <FooterSection posthog={posthog} />
        </div>
    )
}

export default NewWebPage;