export const REFUND_AND_CANCELLATION_POLICY = `
<style>
    .subTitleStyle {
        font-size: 1rem;
        margin-top: 0rem;
        font-weight: 600;
        color: gray;
        line-height: 1.4rem;
        font-family: 'Satoshi';
    }

    .titleStyle {
        font-size: 20px;
        margin-top: 1rem;
        font-weight: 600;
        color: gray;
        line-height: 1.4rem;
        font-family: 'Satoshi';
        margin-bottom: 1.2rem;
        color: black;
    }

    span {
        font-weight: 600;
        color: black;
        font-family: 'Satoshi';
    }

    a{
    font-weight: 600;
    color: 'black';
    text-direction: underline;
    cursor: pointer;
          font-family: 'Satoshi';
    }

    ul{
        padding-left:0.8rem;
        margin:0;
        margin-bottom:1rem;
        font-family: 'Satoshi';
    }
</style>

<p class='subTitleStyle' style='margin-top:4rem'>
Welcome to Sonar Labs. We are committed to providing exceptional service and support for our users.
 However, we understand that there may be instances where users need to cancel their subscriptions or request refunds. Please review our Cancellation and Refund Policy to understand your options and rights.
</p>

<h5 class="titleStyle"> Subscription Cancellation</h5>

<p class='subTitleStyle'>
- Monthly Subscriptions: You may cancel your monthly subscription at any time. Upon cancellation, 
you will continue to have access to Sonar Labs services until the end of your current billing period.
 No further charges will be made after the end of your current billing period.
</p>

<p class='subTitleStyle'>
   - Annual Subscriptions: You may cancel your annual subscription at any time.
    Upon cancellation, you will continue to have access to Sonar Labs services until the end of your current subscription period.
    No further charges will be made after the end of your current subscription period.
</p>

<p class='subTitleStyle'>
   - How to Cancel: To cancel your subscription, please log in to your Sonar Labs account, 
   navigate to the "Account Settings" section, and follow the instructions to cancel your subscription. Alternatively,
   you can contact our support team at support@sonarlabs.ai for assistance.
</p>

<h5 class="titleStyle"> Refund Policy</h5>

<p class='subTitleStyle'>
   - Monthly Subscriptions: Monthly subscriptions are non-refundable. 
   Once a payment has been processed for the current billing cycle, the amount will not be refunded.
    Your subscription will remain active until the end of the paid billing cycle.
</p>

<p class='subTitleStyle'>
      - Annual Subscriptions: Refunds are available for annual subscriptions if requested within the first 14 days of your subscription start date.
       If you cancel your annual subscription within this 14-day period, you are eligible for a full refund. No refunds will be issued after the initial 14-day period. 
      Your subscription will remain active until the end of the subscription period if canceled after 14 days.
</p>

<h5 class="titleStyle"> Exceptional Circumstances for Refunds </h5>

<p class='subTitleStyle'>
In cases of exceptional circumstances, such as technical issues that prevent you from using the service effectively,
 you may be eligible for a prorated refund. Refund requests under exceptional circumstances will be reviewed on a case-by-case basis, 
and refunds are issued at Sonar Labs' sole discretion.
</p>

<p class='subTitleStyle'>
To request a refund under exceptional circumstances, please contact our support team at support@sonarlabs.ai with a detailed explanation of the issue,
 and we will get back to you as soon as possible.
</p>

<h5 class="titleStyle">Modifications to Policy</h5>

<p class='subTitleStyle'>
Sonar Labs reserves the right to modify or update this Cancellation and Refund Policy at any time. Any changes will be posted on our website, and users will be notified via email or account notifications.
 By continuing to use Sonar Labs after the policy changes take effect, you agree to be bound by the updated policy.
</p>

<h5 class="titleStyle"> Contact Us  </h5>

<p class='subTitleStyle'>
If you have any questions about our Cancellation and Refund Policy, please contact us:
</p>

<p class='subTitleStyle'>
Email: support@sonarlabs.ai <br/>
Phone: +16505594914  <br/> <br/>
Address:<br/>
#4 Bellary Road,   <br/>
3rd Floor Vaibhava Center,  <br/>
Bangalore 560080 India.  
</p>

<p class='subTitleStyle' style='margin-bottom:4rem'>
Thank you for choosing Sonar Labs! We appreciate your business and are here to support you.
</p>
`