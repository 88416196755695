import React, { useEffect, useState } from "react";
import "./assets/material.styles.css";
import SearchImg from "./assets/search.svg";
import { ReactComponent as RightArrow } from "./assets/rightArrow.svg";
import { ReactComponent as BackToSearchImg } from "./assets/backToSearch.svg";
import { ReactComponent as ArrowDown } from "./assets/arrowDown.svg";
import LocationCrad from "./components/locationCrad";
import CheckboxDropdown from "./components/checkboxDropdown";
import LocationListDropdown from "./components/locationListDropdown";
import MapsComponent from "./components/mapsComponent";
import LocationsLoader from "./components/locationsLoader";
import { FIND_MATERIAL_PLACE } from "../../services";
import { decryptResponse } from "../../utils/encryptionDecryption";
import { MATERIAL_SEARCH_HISTORY } from "../../services";
import STRINGS, { PROXIMITY_OPTIONS } from "../../helpers/staticContentHelper";
import { FETCH_LOCATION_DATA } from "./googleSerive";
import { GET_REQUEST_PAYLOAD } from "./googleSerive";
import { useFunctionContext } from "../../services/triggerLocationHistoryContext";
import { FETCH_LOCATION_ROUTES } from "./googleSerive";
import { useStoreLocationHistoryContext } from "../../services/storeMaterialSearchContext";
import { ReactComponent as FilterImg } from './assets/filter.svg';
import { Checkbox } from '@mui/material';
import { CircularProgress } from "@mui/material";

export function checkType(value) {
    if (typeof value === 'function') {
        return 'function'
    } else {
        return 'number'
    }
}
function SearchMaterial() {
    const [pointerValue, setPointerValue] = useState(null)
    const { sharedFunction } = useFunctionContext();
    const { locationHistoryValues, setLocationHistoryValues } = useStoreLocationHistoryContext();
    const [isSearchActive, setIsSearchActive] = useState(true)
    const [loading, setIsLoading] = useState(false)
    const [activeLocation, setActiveLoaction] = useState(null)
    const [response, setResponse] = useState(null); //this response is used to add track between two loactions 
    const [searchValue, setSearchValue] = useState('');
    const [isFilterOptionEnabled, setIsFilterOptionEnabled] = useState(false);
    const [isFilterLoading, setIsFilterLoading] = useState(false);
    const [filterOptions, setFilterOptions] = useState([]);
    const [isFilterActive, setisFilterActive] = useState(false);
    const [activeDropDown, setActiveDropDown] = useState('') //for active dropdown
    // locations data after serach 
    const [autoCompleteLocationData, setAutoCompleteLoactionsData] = useState([]);
    const [locationsData, setLoactionsData] = useState([]);
    const [afterFilterLocationsData, setAfterFilterLoactionsData] = useState([]);
    const [destinationLocation, setDestinationLocation] = useState(null)
    const [isAnimationEnabled, setIsAnimationEnabled] = useState(false)
    const [isQueryDone, setIsQueryDone] = useState(false)
    // payload for the api request
    const [apiRequestPayload, setApiRequestPayload] = useState({
        "destination_address": "",
        "materials": [],
        "proximity": null,
        'isValueChanges': false
    })

    const fetchLocationdata = async (key) => {
        try {
            const res = await FETCH_LOCATION_DATA(key)
            setAutoCompleteLoactionsData(res)
        } catch (error) {
            console.log(error)
        }
    }

    const handleGetLocation = async () => {
        setisFilterActive(false)
        setFilterOptions([])
        setIsAnimationEnabled(true)
        setResponse(null)
        setPointerValue(null)
        setApiRequestPayload(prev => {
            return { ...prev, isValueChanges: false }
        })
        setIsLoading(prev => true)
        try {
            let res = await FIND_MATERIAL_PLACE(apiRequestPayload);
            const decryptedData = JSON.parse(decryptResponse(res))
            const formattedAddressData = decryptedData.map(address => {
                return {
                    ...address,
                    name: address?.companyName,
                    address: address?.formattedAddress,
                    phone: address["Phone (M)"] || '',
                    categories: address?.materialsAvailable,
                    lat: address?.location?.latitude,
                    lng: address?.location?.longitude,
                    id: address?.materialPlaceId,
                    contact_name: address?.Contact || '',
                    email: address?.Email || '',
                    officePhone: address?.nationalPhoneNumber || '',
                    website: address["websiteUri"] || ''
                }
            })
            setIsLoading(prev => false)
            setLoactionsData(formattedAddressData)
            //this helps to reserv the current state of query with results data
            setLocationHistoryValues(prev => {
                return { ...prev, locationsData: formattedAddressData, apiRequestPayload, destinationLocation }
            })
            setIsQueryDone(true)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const handler = setTimeout(() => {
            if (searchValue) {
                fetchLocationdata(searchValue)
            }
        }, 500); // 500ms delay for search
        return () => {
            clearTimeout(handler); // Cleanup timeout if query changes
        };
    }, [searchValue]);

    const storeHistory = async (payload) => {
        await MATERIAL_SEARCH_HISTORY(payload)
        sharedFunction(Math.floor(Math.random() * 10001))
    }

    const handleOnCardClick = async (cardData, idFromMap = false) => {
        if (cardData?.id === response?.materialPlaceId) {
            setResponse(null)
            setPointerValue(null)
            return
        }
        const reqPayloadForDirection = {
            origin: {
                lat: cardData.location.latitude,
                lng: cardData.location.longitude,
            },
            destination: destinationLocation
        }

        let responseForRoute = await FETCH_LOCATION_ROUTES(reqPayloadForDirection)
        responseForRoute.availableRoutes = responseForRoute.routes
        responseForRoute.routes = [responseForRoute.availableRoutes[0]]
        responseForRoute.activeRoute = 0
        responseForRoute.materialPlaceId = cardData.materialPlaceId
        setResponse(responseForRoute)
        triggerSetPointerValue(responseForRoute.routes[0])

        let reqPayload = GET_REQUEST_PAYLOAD(cardData, apiRequestPayload, destinationLocation);
        const duration = responseForRoute.routes[0].legs[0].duration.text
        const distance = (responseForRoute.routes[0].legs[0].distance.value / 1609.34).toFixed(2)
        reqPayload.distance.time = duration
        reqPayload.distance.miles = parseFloat(distance)
        storeHistory(reqPayload)

        setLocationHistoryValues(prev => {
            return { ...prev, response: responseForRoute }
        })

        if (idFromMap) {
            scrollToDiv(cardData.materialPlaceId)
        }
    }

    const checkValidation = () => {
        return apiRequestPayload.destination_address && apiRequestPayload.proximity && apiRequestPayload.materials.length > 0
    }

    function scrollToDiv(divId) {
        const targetDiv = document.getElementById(divId);
        if (targetDiv) {
            targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    const triggerSetPointerValue = (routeData) => {
        let latitude = checkType(routeData.legs[0].steps[0].start_point.lat) === 'function' ? routeData.legs[0].steps[0].start_point.lat() : routeData.legs[0].steps[0].start_point.lat
        let longitude = checkType(routeData.legs[0].steps[0].start_point.lng) === 'function' ? routeData.legs[0].steps[0].start_point.lng() : routeData.legs[0].steps[0].start_point.lng
        let middleValue = Math.round(routeData.legs[0].steps.length / 2);
        const actualPointerValue = {
            ...routeData.legs[0].steps[middleValue].start_point,
            lat: latitude,
            lng: longitude,
            distance: (routeData.legs[0].distance.value / 1609.34).toFixed(2),
            duration: routeData.legs[0].duration.text,
        }
        setPointerValue(actualPointerValue)
        setLocationHistoryValues(prev => {
            return { ...prev, pointerValue: actualPointerValue }
        })
    }

    const setPrevSearchValues = (materialSearchData) => {
        if (materialSearchData) {
            let { apiRequestPayload, response, pointerValue, locationsData, destinationLocation } = materialSearchData;
            setDestinationLocation(destinationLocation || null)
            setLoactionsData(locationsData || [])
            setIsSearchActive(false)
            if (apiRequestPayload) setApiRequestPayload(apiRequestPayload)
            setPointerValue(pointerValue || null)
            setResponse(response || null)
        }
    }

    useEffect(() => {
        if (Object.keys(locationHistoryValues).length) {
            setPrevSearchValues(locationHistoryValues)
        }
    }, [])


    const applyFilter = async () => {
        setIsFilterLoading(prev => true)
        setIsFilterOptionEnabled(false)
        let filteredData = []
        locationsData.forEach(locationCard => {
            const isPresent = locationCard.categories.some(item => filterOptions.includes(item));
            if (isPresent) {
                filteredData.push(locationCard)
            }
        })
        await new Promise((resolve) => setTimeout(resolve, 500));
        setIsFilterLoading(prev => false)
        setAfterFilterLoactionsData(filteredData)
        setisFilterActive(true)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: "100%", flex: 1 }} onClick={() => {
            setActiveDropDown('')
            setIsFilterOptionEnabled(false)
        }}>
            <p style={{ paddingLeft: "1rem", marginTop: "2rem", marginBottom: '0px' }}>{STRINGS.SEARCH_MATERIAL}</p>
            <div className="search-material-main-container">
                <div className="search-material-container"
                    style={{ cursor: !isSearchActive && 'pointer' }}
                    onClick={() => {
                        if (!isSearchActive) {
                            setIsSearchActive(true)
                        }
                    }}>
                    <div className={`material-search-filter-with-icon ${!isSearchActive && 'decrease-width'}`}>
                        {isSearchActive && <p className={`filter-header ${isAnimationEnabled && 'filter-header-animate-in'}`}>{STRINGS.PROJECT_ADDRESS}</p>}
                        <div className="about-filter-with-icon">
                            <input
                                className="no-border-input-box"
                                style={{ cursor: !isSearchActive && 'pointer' }}
                                placeholder={apiRequestPayload?.destination_address ?
                                    apiRequestPayload?.destination_address.length > 22 ?
                                        apiRequestPayload?.destination_address.substring(0, !isSearchActive ? 13 : 22) + '...' :
                                        apiRequestPayload?.destination_address :
                                    "Enter job number or address"}
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                            />
                            {isSearchActive &&
                                <img src={SearchImg} alt="no" />}
                        </div>
                        {searchValue &&
                            <LocationListDropdown
                                setSearchValue={setSearchValue}
                                autoCompleteLocationData={autoCompleteLocationData}
                                setDestinationLocation={setDestinationLocation}
                                setApiRequestPayload={setApiRequestPayload} />
                        }
                    </div>

                    <div className="devide-indicator"></div>

                    <div className={`material-search-filter-with-icon ${!isSearchActive && 'decrease-width'}`}>
                        {isSearchActive &&
                            <p className={`filter-header ${isAnimationEnabled && 'filter-header-animate-in'}`}>{STRINGS.PROXIMITY}</p>}
                        <div className="about-filter-with-icon" onClick={(e) => {
                            if (isSearchActive) {
                                e.stopPropagation();
                                setActiveDropDown(STRINGS.PROXIMITY)
                            }
                        }}>
                            <span className="filter-sub-text">{apiRequestPayload.proximity ? apiRequestPayload.proximity + ' miles' : 'Select the distance'}</span>
                            {isSearchActive && <ArrowDown />}
                        </div>
                        {activeDropDown === STRINGS.PROXIMITY &&
                            <div className="search-result-for-location hide-scrollbar" style={{ width: "240px", maxHeight: "250px", alignItems: "start", backgroundColor: 'white', overflowY: "scroll", overflowX: 'hidden', zIndex: 99999 }}>
                                {PROXIMITY_OPTIONS.map((km, index) => {
                                    return (
                                        <div className="option-for-mile-selection" onClick={() => setApiRequestPayload(prev => {
                                            setActiveDropDown('')
                                            return { ...prev, proximity: km.value, isValueChanges: true }
                                        })}>
                                            {km.key}
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>

                    <div className="devide-indicator"></div>

                    <div className={`material-search-filter-with-icon ${!isSearchActive && 'decrease-width'}`} >
                        {isSearchActive ?
                            <>
                                <p className={`filter-header ${isAnimationEnabled && 'filter-header-animate-in'}`}>{STRINGS.PRODUCT_MATERIAL}</p>
                                <div className="about-filter-with-icon"
                                    onClick={(e) => {
                                        if (isSearchActive) {
                                            e.stopPropagation()
                                            setActiveDropDown(STRINGS.PRODUCT_MATERIAL)
                                        }
                                    }}>
                                    <span className="filter-sub-text">{apiRequestPayload.materials.length > 0 ? apiRequestPayload.materials.join(", ").substring(0, 20) : 'Select the materials'}</span>
                                    {apiRequestPayload.materials.length > 1 &&
                                        <div className="materials-more-chip">+{apiRequestPayload.materials.length - 1}</div>
                                    }
                                    <ArrowDown />
                                </div>
                            </> :
                            <span className="filter-sub-text">{apiRequestPayload.materials.length} {apiRequestPayload.materials.length === 1 ? 'material' : 'materials'}</span>
                        }
                        {activeDropDown === STRINGS.PRODUCT_MATERIAL &&
                            <div className="search-result-for-location" style={{ width: "auto", left: "-1rem", padding: "1rem", alignItems: "start", backgroundColor: 'white', zIndex: 99999 }}>
                                <p className="filter-header">{STRINGS.SELECT_PRODUCT_TYPE}</p>
                                <CheckboxDropdown
                                    setApiRequestPayload={setApiRequestPayload}
                                    apiRequestPayload={apiRequestPayload} />
                            </div>}
                    </div>
                    {isSearchActive ?
                        <RightArrow className={`${checkValidation() && 'active-arrow'}`} style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (checkValidation()) {
                                    if (apiRequestPayload.isValueChanges) handleGetLocation()
                                    setIsSearchActive(false)
                                    setIsQueryDone(false)
                                }
                            }} />
                        : <BackToSearchImg style={{ cursor: "pointer" }} />
                    }
                </div>
            </div>

            {/* detail section */}
            <div className="result-for-the-material-serah-component">
                <div className={`location-list-of-origins hide-scrollbar ${!isSearchActive && 'location-list-of-origins-active'} `}>
                    {!loading && locationsData.length > 0 &&
                        <div className="filter-options-for-location-card">
                            <p className={`${isFilterLoading && 'loading-text-gray'}`}>
                                {isFilterLoading ? STRINGS.FINDING_THE_BEST_MATCHES_FOR_YOU :
                                    isFilterActive ? afterFilterLocationsData.length : locationsData.length
                                } {!isFilterLoading && ' ' + STRINGS.FECILITIES_FOUND}
                                {isFilterLoading &&
                                    <CircularProgress color="success" size="0.8rem" sx={{ ml: '0.5rem' }} />
                                }
                            </p>
                            <div className={`${isFilterActive && filterOptions.length > 0 && 'location-filter-img-after'} location-filter-img`}>
                                <FilterImg className="filter-image" onClick={(e) => {
                                    e.stopPropagation()
                                    setIsFilterOptionEnabled(prev => !prev)
                                }} />
                                {isFilterOptionEnabled &&
                                    <div className="search-result-for-location-filter hide-scrollbar" onClick={(e) => e.stopPropagation()} style={{ width: "200px", alignItems: "start", backgroundColor: 'white', overflowY: "scroll", overflowX: 'hidden', zIndex: 99999 }}>
                                        {apiRequestPayload.materials.map((filterOption, index) => {
                                            return (
                                                <div className="option-for-mile-selection" onClick={(e) => {
                                                    if (filterOptions.includes(filterOption)) {
                                                        const filteredList = filterOptions.filter(i => i !== filterOption);
                                                        const filteredSecondList = filteredList.filter(i => i !== 'allChecked');
                                                        setFilterOptions(filteredSecondList)
                                                    } else {
                                                        if ([...filterOptions, filterOption].length === apiRequestPayload.materials.length) {
                                                            setFilterOptions(prev => [...prev, filterOption, 'allChecked'])
                                                        } else {
                                                            setFilterOptions(prev => [...prev, filterOption])
                                                        }
                                                    }
                                                }}>
                                                    <Checkbox
                                                        sx={{
                                                            color: '#1F5D5E',
                                                            '&.Mui-checked': {
                                                                color: '#1F5D5E',
                                                            },
                                                            padding: '0px',
                                                        }}
                                                        id="myCheckbox"
                                                        checked={filterOptions.includes(filterOption)}
                                                    />
                                                    <span className="filter-sub-text" for="myCheckbox" style={{ marginLeft: "0.4rem" }}>{filterOption}</span>
                                                </div>
                                            )
                                        })}
                                        <div className="option-for-mile-selection" onClick={() => {
                                            if (!filterOptions.includes('allChecked')) {
                                                setFilterOptions([...apiRequestPayload.materials, 'allChecked'])
                                            } else {
                                                setFilterOptions([])
                                            }
                                        }}>
                                            <Checkbox
                                                sx={{
                                                    color: '#1F5D5E',
                                                    '&.Mui-checked': {
                                                        color: '#1F5D5E',
                                                    },
                                                    padding: '0px',
                                                }}
                                                id="myCheckbox"
                                                checked={filterOptions.includes('allChecked')}
                                            />
                                            <span className="filter-sub-text" for="myCheckbox" style={{ marginLeft: "0.4rem" }}>Select all</span>
                                        </div>
                                        <div className="filter-action-buttons">
                                            <span className="filter-close-button" onClick={() => {
                                                setIsFilterOptionEnabled(() => false);
                                                setisFilterActive(false)
                                                setAfterFilterLoactionsData([])
                                                setFilterOptions([])
                                            }}>
                                                {STRINGS.CLEAR}
                                            </span>
                                            <span className="filter-apply-button" onClick={() => {
                                                applyFilter()
                                            }}>
                                                {STRINGS.APPLY}
                                            </span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    }
                    {loading ? <LocationsLoader /> :
                        isFilterActive ?
                            <>
                                {afterFilterLocationsData.length > 0 ?
                                    afterFilterLocationsData.map((value, index) => {
                                        return (
                                            <LocationCrad
                                                setActiveLoaction={setActiveLoaction}
                                                cardData={value}
                                                destinationLocation={destinationLocation}
                                                apiRequestPayload={apiRequestPayload}
                                                handleOnCardClick={handleOnCardClick}
                                                response={response}
                                                filterOptions={filterOptions}
                                                isFilterActive={isFilterActive}
                                            />
                                        )
                                    })
                                    :
                                    <p style={{ textAlign: "center" }}>Sorry, looks like we couldn’t find any suppliers</p>}
                            </>
                            :
                            (locationsData.length > 0 || isQueryDone) ?
                                <>
                                    {
                                        locationsData.map((value, index) => {
                                            return (
                                                <LocationCrad
                                                    setActiveLoaction={setActiveLoaction}
                                                    cardData={value}
                                                    destinationLocation={destinationLocation}
                                                    apiRequestPayload={apiRequestPayload}
                                                    handleOnCardClick={handleOnCardClick}
                                                    response={response}
                                                    filterOptions={filterOptions}
                                                    isFilterActive={isFilterActive}
                                                />
                                            )
                                        })
                                    }
                                   {locationsData.length === 0 && isQueryDone && <p style={{ textAlign: "center" }}>Sorry, looks like we couldn’t find any suppliers</p>}
                                </>
                                :
                                <></>
                    }
                </div>

                <div style={{ flex: 1, backgroundColor: loading && '#F6F7FA', borderRadius: '12px', overflow: "hidden", height: '100%', position: "relative" }}>
                    <div className="multiple-route-details">
                        {response?.availableRoutes.length > 0 && response?.availableRoutes.map((routeData, index) => {
                            let activeRoute = response?.activeRoute || 0
                            return (
                                <div key={index} className={`route-detail-card ${activeRoute === index && 'active-route-card'}`} onClick={() => {
                                    if (response?.availableRoutes.length > 0) {
                                        let deepCopy = JSON.parse(JSON.stringify(response))
                                        deepCopy.routes = [routeData]
                                        deepCopy.activeRoute = index
                                        setResponse(deepCopy)
                                        triggerSetPointerValue(routeData)
                                        setLocationHistoryValues(prev => {
                                            return { ...prev, response: deepCopy }
                                        })
                                    }
                                }}>
                                    <p>Route {index + 1}</p>
                                    <span>{routeData.legs[0].duration.text}</span>
                                </div>
                            )
                        })}
                    </div>
                    {((locationsData.length > 0 && destinationLocation && !loading) || isQueryDone) &&
                        <MapsComponent
                            response={response}
                            setResponse={setResponse}
                            activeLocation={activeLocation}
                            setActiveLoaction={setActiveLoaction}
                            destinationLocation={destinationLocation}
                            locationsData={isFilterActive ? afterFilterLocationsData : locationsData}
                            pointerValue={pointerValue}
                            setPointerValue={setPointerValue}
                            handleOnCardClick={(value) => {
                                handleOnCardClick(value, true)
                            }} />
                    }
                </div>
            </div>
        </div>
    );
}

export default SearchMaterial;