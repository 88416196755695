import React from 'react';
import axios from 'axios';
import { GET_LAT_LNG_FROM_ADDRESS } from '../googleSerive';

function LocationListDropdown({ autoCompleteLocationData, setSearchValue, setDestinationLocation, setApiRequestPayload }) {

    const getLatLngFromPlaceId = async (address) => {
        try {
            let res = await GET_LAT_LNG_FROM_ADDRESS(address)
            setDestinationLocation(res)
        } catch (error) {
            console.error('Error fetching place details:', error);
        }
    };

    return (
        <div className="search-result-for-location" style={{ backgroundColor: "white", zIndex: 99999 }}>
            {autoCompleteLocationData.map(locationData => {
                return (
                    <div className="result-data-for-location" onClick={() => {
                        getLatLngFromPlaceId(locationData.text)
                        setApiRequestPayload(prev => {
                            return { ...prev, destination_address: locationData.text , isValueChanges: true }
                        })
                        setSearchValue('')
                    }}>
                        {/* <span>{locationData.text}</span> */}
                        <p>{locationData.text}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default LocationListDropdown;