import React from 'react'
import { ReactComponent as GobeImg } from "../assets/globe.svg";
import { ReactComponent as EmailImg } from "../assets/email.svg";
import { ReactComponent as PhoneImg } from "../assets/phone.svg";
import { ReactComponent as VerifiedImg } from "../assets/verified.svg";
import { ReactComponent as WebsiteImg } from "../assets/website.svg";
import STRINGS from '../../../helpers/staticContentHelper'
export const center_flex = {
    display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: "0.5rem"
}

function LocationCrad({ filterOptions = [], isFilterActive, handleOnCardClick, setActiveLoaction, cardData, staticRrender = false, response, hideOnHover = false }) {
    // Find the index of the first colon
    const colonIndex = cardData?.openHours?.currentTiming?.indexOf(":");

    // Split the string using substring
    const label = cardData?.openHours?.currentTiming?.substring(0, colonIndex);
    const rest = cardData?.openHours?.currentTiming?.substring(colonIndex + 1).trim();
    return (
        <div className="location-detail-card" id={`${cardData.materialPlaceId}`} style={{ backgroundColor: (response?.materialPlaceId === cardData.materialPlaceId && !hideOnHover) && '#F6F7FA', border: response?.materialPlaceId === cardData.materialPlaceId ? '1px solid #8fb7b5' : '1px solid white' }} onClick={() => {
            if (!staticRrender) {
                handleOnCardClick(cardData)
            }
        }}
            onMouseEnter={() => {
                if (!staticRrender) {
                    setActiveLoaction(cardData.id)
                }
            }}
            onMouseLeave={() => {
                if (!staticRrender) {
                    setActiveLoaction(null)
                }
            }}>
            <div className="location-card-title">
                <div className='location-card-name'>
                    <p style={{ maxWidth: "440px" }}>{cardData?.name}
                        {cardData?.sonarVerified &&
                            <div className='is-verified-by-sonar'>
                                <VerifiedImg style={{ position: "absolute", top: '-1rem', left: "0.2rem" }} />
                                <p className='verified-by-sonar-tooltip'>{STRINGS.VERIFIED_BY_SONAR_LABS}</p>
                            </div>}
                    </p>
                </div>
                <div style={{ display: 'flex', gap: "1rem" }}>
                    <div className="location-card-images">
                        <GobeImg />
                    </div>
                </div>
            </div>

            {cardData?.openHours?.currentTiming && <div className='detail-with-heading-for-location'>
                <span style={{ color: label === 'Open' ? 'green' : 'tomato' }}>{label}:</span>
                <p>{rest}</p>
            </div>}
            <div className='detail-with-heading-for-location'>
                <span>{STRINGS.ADDRESS_LABEL}</span>
                <p>{cardData.address}</p>
            </div>

            {(cardData?.officePhone || cardData?.website) &&
                <div className='detail-with-heading-for-location'>
                    <span>{STRINGS.OFFICE_LABEL}</span>
                    {cardData?.officePhone &&
                        <div style={center_flex}>
                            <PhoneImg />
                            <p>{cardData?.officePhone}
                            </p>
                        </div>
                    }
                    {cardData?.website &&
                        <div style={{ ...center_flex }}>
                            <WebsiteImg />
                            <a href={cardData?.website} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#666666' }}>
                                <p>{STRINGS.WEBSITE_TEXT}</p>
                            </a>
                        </div>
                    }
                </div>
            }
            {(cardData?.phone || cardData?.contact_name || cardData?.email) &&
                <div className='detail-with-heading-for-location'>
                    <span>{STRINGS.SALES_LABEL}</span>
                    {cardData.contact_name &&
                        <p>{cardData.contact_name}</p>
                    }
                    {cardData?.phone &&
                        <div style={center_flex}>
                            <PhoneImg />
                            <p> {cardData?.phone}</p>
                        </div>
                    }
                    {cardData.email &&
                        <div style={{ ...center_flex }}>
                            <EmailImg />
                            <p> {cardData?.email}</p>
                        </div>
                    }
                </div>
            }

            <div className='location-search-more-details'>
                {cardData?.categories.length > 0 &&
                    cardData?.categories.map(category => {
                        return (
                            <div className={`${filterOptions.includes(category) && isFilterActive && 'active-location-search-detail-card'} location-search-detail-card`}>
                                <p>{category}</p>
                            </div>
                        )
                    })}
            </div>

        </div>
    )
}

export default LocationCrad