import React from 'react'

function LocationsLoader() {
    return (
        <>
            {[0, 1, 2].map(number => {
                return <div className='location-card-while-loading' />
            })}
        </>
    )
}

export default LocationsLoader;