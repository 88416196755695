import React, { useEffect, useState } from 'react'
import DoubleArrow from '../../assests/modalCross.svg';
import LocationCrad from '../../pages/materialSearch/components/locationCrad';
import MapsComponent from '../../pages/materialSearch/components/mapsComponent';
import { checkType } from '../../pages/materialSearch/searchForMaterial';
import { FETCH_LOCATION_ROUTES } from '../../pages/materialSearch/googleSerive';

function MaterialHistoryModal({ setIsModalOpen, isModalOpen, selectedLocation }) {
    const [response, setResponse] = useState(null);
    const [pointerValue, setPointerValue] = useState(null);
    const handleModal = () => {
        setIsModalOpen(false)
    }
    const handleOnCardClick = () => { }

    const triggerSetPointerValue = (routeData) => {
        let latitude = checkType(routeData.legs[0].steps[0].start_point.lat) === 'function' ? routeData.legs[0].steps[0].start_point.lat() : routeData.legs[0].steps[0].start_point.lat
        let longitude = checkType(routeData.legs[0].steps[0].start_point.lng) === 'function' ? routeData.legs[0].steps[0].start_point.lng() : routeData.legs[0].steps[0].start_point.lng

        let middleValue = Math.round(routeData.legs[0].steps.length / 2);
        setPointerValue({
            ...routeData.legs[0].steps[middleValue].start_point,
            lat: latitude,
            lng: longitude,
            distance: (routeData.legs[0].distance.value / 1609.34).toFixed(2),
            duration: routeData.legs[0].duration.text,
        })
    }

    const handleOnOnRenderCard = async (cardData) => {
        const reqPayloadForDirection = {
            origin: {
                lat: cardData?.location?.latitude,
                lng: cardData?.location?.longitude,
            },
            destination: {
                lat: cardData?.destination_details?.location?.latitude,
                lng: cardData?.destination_details?.location?.longitude,
            }
        }

        await new Promise(resolve => setTimeout(resolve, 800));

        let responseForRoute = await FETCH_LOCATION_ROUTES(reqPayloadForDirection)
        responseForRoute.availableRoutes = responseForRoute.routes
        responseForRoute.routes = [responseForRoute.availableRoutes[0]]
        responseForRoute.activeRoute = 0
        responseForRoute.materialPlaceId = cardData.materialPlaceId
        setResponse(responseForRoute)
        triggerSetPointerValue(responseForRoute.routes[0])
    }

    useEffect(() => {
        if (selectedLocation) {
            handleOnOnRenderCard(selectedLocation)
        }
    }, [selectedLocation])

    return (
        <div className={`pdf-side-view-modal-absolute`}
            style={{
                width: isModalOpen ? '100%' : '0px',
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: '0px',
                height: 'calc(100vh)'
            }} onClick={handleModal} >
            <div className={`pdf-side-view-modal pdf-side-view-modal-absolute`} style={{ width: isModalOpen ? '600px' : '0px', height: 'calc(100vh - 0.2rem)' }}
                onClick={(e) => e.stopPropagation()}>
                {isModalOpen &&
                    <>
                        <div className='pdf-side-view-modal-header'>
                            <div className='double-arrow-with-title'>
                                <p>Supplier details</p>
                            </div>
                            <img src={DoubleArrow} alt='not-found' onClick={handleModal} style={{ cursor: 'pointer' }} />
                        </div>
                        <div className='project-level-history' style={{ padding: '1rem' }}>
                            <LocationCrad
                                cardData={{
                                    name: selectedLocation?.company_name,
                                    address: selectedLocation?.formatted_address,
                                    phone:  selectedLocation?.sales_phone_number || '',
                                    categories: selectedLocation?.materials_available || [],
                                    lat: selectedLocation?.location?.latitude,
                                    lng: selectedLocation?.location?.longitude,
                                    id: selectedLocation?.id,
                                    contact_name: selectedLocation?.contact_person || '',
                                    email: selectedLocation.contact_email || '',
                                    openHours: selectedLocation?.open_hours,
                                    officePhone: selectedLocation?.national_phone_number || '',
                                    website: selectedLocation?.website || '',
                                    sonarVerified: selectedLocation?.sonar_verified

                                }}
                                staticRrender={true}
                                handleOnCardClick={handleOnCardClick}
                                hideOnHover={true}
                            />
                            <div className='map-component-for-history-modal' style={{ maxWidth: '570px', height: "100%", borderRadius: "8px", overflow: 'hidden', marginTop: "1rem", position: "relative" }}>
                                <div className="multiple-route-details">
                                    {response?.availableRoutes.length > 0 && response?.availableRoutes.map((routeData, index) => {
                                        let activeRoute = response?.activeRoute || 0
                                        return (
                                            <div className={`route-detail-card ${activeRoute === index && 'active-route-card'}`} onClick={() => {
                                                if (response?.availableRoutes.length > 0) {
                                                    let deepCopy = JSON.parse(JSON.stringify(response))
                                                    deepCopy.routes = [routeData]
                                                    deepCopy.activeRoute = index
                                                    setResponse(deepCopy)
                                                    triggerSetPointerValue(routeData)
                                                }
                                            }}>
                                                <p>Route {index + 1}</p>
                                                <span>{routeData.legs[0].duration.text}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                                <MapsComponent
                                    locationsData={[{
                                        name: selectedLocation?.company_name,
                                        address: selectedLocation?.formatted_address,
                                        phone: selectedLocation?.national_phone_number || '',
                                        categories: selectedLocation?.materials_available || [],
                                        lat: selectedLocation?.location?.latitude,
                                        lng: selectedLocation?.location?.longitude,
                                        id: selectedLocation?.id,
                                        contact_name: selectedLocation?.contact_person || '',
                                        email: selectedLocation.contact_email || ''
                                    }]}
                                    activeLocation={selectedLocation?.id}
                                    destinationLocation={{
                                        lat: selectedLocation?.destination_details?.location?.latitude,
                                        lng: selectedLocation?.destination_details?.location?.longitude,
                                    }}
                                    setResponse={setResponse}
                                    response={response}
                                    handleOnCardClick={handleOnCardClick}
                                    pointerValue={pointerValue}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default MaterialHistoryModal;

