import React, { useState, useEffect } from 'react'
import LogoWithName from '../../assests/sonar.png';
import SonarLogo from '../../assests/SonarNewLogo.svg';
import NavBarToggler from '../../assests/navbarToggler.svg';
import HistoryInActiveImg from '../../assests/historyInActive.svg';
import HistoryActiveImg from '../../assests/historyActive.svg';
import FileFolderActiveImg from '../../assests/fileFolderActive.svg';
import FileFolderInactiveImg from '../../assests/fileFolderInactive.svg';
import BackImg from '../../assests/back.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import STRINGS from '../../helpers/staticContentHelper';
import { LStorage } from '../../utils/localStorage.Helper';
import { LIST_MATERIAL_SEARCH_HISTORY } from '../../services';
import { decryptResponse } from '../../utils/encryptionDecryption';
import { useSearchParams } from 'react-router-dom';
import { useFunctionContext } from '../../services/triggerLocationHistoryContext';
import { GET_ORG_DETAILS } from '../../services';

function MaterialSideBar() {
    const { value } = useFunctionContext();
    const [searchParams, setSearchParams] = useSearchParams()
    let historyId = searchParams.get('historyId')
    const [history, setSearchHistory] = useState([])
    const location = useLocation();
    const path = location.pathname;
    let userData = LStorage.getUserData()
    const navigate = useNavigate();
    const [isTogglerOn, setIsTogglerOn] = useState(true);

    useEffect(() => {
        const getListOfHistroty = async () => {
            try {
                let res = await LIST_MATERIAL_SEARCH_HISTORY();
                const parsedResponse = JSON.parse(decryptResponse(res))
                setSearchHistory(parsedResponse)
                console.log(parsedResponse)
            } catch (error) {
                console.log(error)
            }
        }
        getListOfHistroty()
    }, [value])

    const getOrgDetails = async () => {
        const reposnse = await GET_ORG_DETAILS();
        const decryptedData = decryptResponse(reposnse)
        const parsedDecryptedData = JSON.parse(decryptedData)
        const { organisation_logo_url } = parsedDecryptedData;
        userData.org_logo = organisation_logo_url
        LStorage.setUserData(userData)
    }
    return (
        <div className={`project-details-navbar-main  ${!isTogglerOn && 'toggler-off'}`}>
            <div className='project-details-navbar'>
                <div className='navbar-header'>
                    {isTogglerOn ?
                        <>
                            <img src={LogoWithName} alt='not-found' height={'32px'} />
                            <img src={NavBarToggler}
                                alt='not-found'
                                onClick={() => setIsTogglerOn(prev => !prev)}
                                className='toggler-icon'
                            />
                        </> :
                        <>
                            <img src={SonarLogo} alt='not-found' className='oh-hover-hide' />
                            <div className='navbar-option on-hover-show'
                                onClick={() => setIsTogglerOn(prev => !prev)}
                            >
                                <img src={NavBarToggler}
                                    alt='not-found'
                                />
                            </div>
                        </>
                    }
                </div>
                <div className='divider-hor'></div>
                <div className={`navbar-option ${path === '/material-search' && 'active-tab'}`}
                    onClick={() => navigate('/material-search')}>
                    <img src={`${path === '/material-search' ? FileFolderActiveImg : FileFolderInactiveImg}`} alt='not-found' width='20px' height='20px' />
                    <span>Search material</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>Search material</p>
                    }
                </div>
                <div className={`navbar-option ${path === '/material-history' && 'active-tab'}`}
                    onClick={() => navigate('/material-history')}>
                    <img src={`${path === '/material-history' ? HistoryActiveImg : HistoryInActiveImg}`} alt='not-found' width='20px' height='20px' />
                    <span>{STRINGS.HISTORY}</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.HISTORY}</p>
                    }
                </div>

                {isTogglerOn &&
                    <div style={{ marginLeft: '1.2rem', display: 'flex', gap: '0.1rem', flexDirection: "column", borderLeft: "1.5px solid #c9c6c6", paddingLeft: '0.2rem' }}>
                        {history.length > 0 &&
                            history.slice(0, 5).map(searchHistory => {
                                return (
                                    <div key={searchHistory.id} className='navbar-option'
                                        style={{ padding: '4px 5px', fontSize: '0.75rem', overflow: "hidden", color: 'gray', backgroundColor: historyId === searchHistory?.history_id && "#ECF0F3" }}
                                        onClick={() => {
                                            navigate(`/material-history?historyId=${searchHistory.history_id}`)
                                        }}
                                    >
                                        {searchHistory?.company_name?.substring(0, 27)}
                                        {searchHistory?.company_name?.length > 27 && '...'}
                                    </div>
                                )
                            })}
                    </div>
                }
            </div>
            {/* navbar button  */}
            <div className='project-details-navbar'>

                <div className='project-info' onClick={() => navigate('/projects')}>
                    <img src={BackImg} alt='not-found' className='thread-icon on-hover-move' width='20px' />
                    <div className='navbar-option-no-hover'>
                        <img src={BackImg} alt='not-found' width='20px' height='20px' className='on-hover-move' />
                        <span className='back-to-project'>
                            Back to dashboard
                        </span>
                    </div>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>Back to dashboard</p>
                    }
                </div>
                <div className='navbar-option-profile' style={{ marginTop: '1rem' }}>
                    <img src={(userData?.org_id && userData?.org_logo) ? userData?.org_logo : SonarLogo} alt='not-found' onerror={(e) => {
                        getOrgDetails()
                    }} />
                    {isTogglerOn &&
                        <span>{userData?.org_id ? userData?.org_name : userData?.first_name + " " + userData?.last_name}</span>
                    }
                </div>
            </div>
        </div>
    )
}

export default MaterialSideBar;