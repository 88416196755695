import React, { useEffect } from 'react'
import { decryptResponse } from '../../utils/encryptionDecryption';
import { useNavigate } from 'react-router-dom';
import { KINDE_AUTH_URL } from '../../services/user.services';
import { LStorage } from '../../utils/localStorage.Helper';

function KindeCallback() {
    const navigate = useNavigate();

    useEffect(() => {
        const reqData = { "kinde_auth_url": `${window.location.href}` }
        KINDE_AUTH_URL(reqData).then(res => {
            try {
                if (res) {
                    const decryptedData = decryptResponse(res)
                    const parsedDecryptedData = JSON.parse(decryptedData)
                    if (parsedDecryptedData?.url) {
                        if (parsedDecryptedData?.url) {
                            window.open(parsedDecryptedData.url, "_blank");
                        }
                    } else {
                        const user = {
                            "first_name": parsedDecryptedData?.given_name,
                            "last_name": parsedDecryptedData?.family_name,
                            "email": parsedDecryptedData?.email,
                            "access_token": parsedDecryptedData?.access_token,
                            'role': parsedDecryptedData?.role,
                            'org_id': parsedDecryptedData?.org_id,
                            'org_name': parsedDecryptedData?.org_name,
                            'org_logo': parsedDecryptedData?.org_logo,
                            'permissions':parsedDecryptedData?.permissions || []
                        }
                        LStorage.setUserData(user)
                        navigate('/projects')
                    }
                }
            } catch (error) {
                navigate('/')
            }
        })
    }, []);

    return (
        <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: "100vh" }}>
            <div class="loading-dots">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
    )
}

export default KindeCallback;