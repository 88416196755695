import React from 'react';
import MaterialSideBar from './materialSidebar';

function MaterialLayout({ children }) {
    return (
        <div className='projects-details-main'>
            {/* left nav bar  */}
            <MaterialSideBar />
            {/* material search chils compoenet */}
            <div className='project-files-details' style={{padding:"0px"}}>
                {children}
            </div>
        </div>
    )
}

export default MaterialLayout;