import React from 'react'
import { GoogleMap, useJsApiLoader, OverlayView, DirectionsRenderer, InfoWindow } from '@react-google-maps/api';
import { ReactComponent as ActivePointerImg } from '../assets/activePointer.svg'
import { ReactComponent as InactivePointerImg } from '../assets/inactivePointer.svg'
import { ReactComponent as ConstrctionPointImg } from '../assets/constrctionPlace.svg'
import { ReactComponent as CarPointImg } from '../assets/car.svg';
import OhHoverLocationDetails from './ohHoverLocationDetails';
const containerStyle = {
  width: '100%',
  height: '100%',
};
function MapsComponent({ pointerValue, handleOnCardClick, response, activeLocation, destinationLocation, locationsData }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })
  const [map, setMap] = React.useState(null)
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    // If it's an array of locations, extend the bounds for each location
    if (Array.isArray(locationsData) && locationsData.length > 0) {
      locationsData.slice(0,15).forEach((loc) => bounds.extend(loc));
    } else {
      bounds.extend(destinationLocation); // Extend for a single location
    }
    map.fitBounds(bounds); // Adjust the map to fit the bounds
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={destinationLocation}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        streetViewControl: false,  // Hide Pegman (Street View) control
        fullscreenControl: false,  // Optionally hide fullscreen control
        mapTypeControl: false,
      }}
    >
      {pointerValue &&
        <InfoWindow position={pointerValue}>
          <div
            className='direction-details'>
            <div className='direction-in-miles'>
              <CarPointImg />
              <span style={{ fontSize: '12px', fontWeight: '300' }}>{pointerValue?.duration}</span>
            </div>
            <span style={{ color: "#6D6D6D", fontSize: '10px', fontWeight: '300' }}>{pointerValue?.distance} miles</span>
          </div>
        </InfoWindow>
      }

      {response && (
        <DirectionsRenderer
          options={{
            directions: response,
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: "#016d6b", // Set custom route color (Tomato Red)
              strokeOpacity: 0.9, // Adjust opacity if needed
              strokeWeight: 5, // Adjust line thickness
            },
          }} />
      )}
      {/* route caliculator end*/}
      {/* render labels  */}
      <OverlayView
        position={destinationLocation}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div className={`pulse-animate custom-marker`}>
          <ConstrctionPointImg />
        </div>
      </OverlayView>
      {locationsData.length > 0 && locationsData.map((mark, index) => {
        return (
          <OverlayView
            position={mark}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div className={`${(activeLocation === mark.id) && 'pulse-animate'} custom-marker`}
              // onMouseEnter={() => setActiveLoaction(mark.id)}
              // onMouseLeave={() => setActiveLoaction(null)}
              onClick={() => {
                handleOnCardClick(mark)
              }}
            >
              {(activeLocation === mark.id) ?
                <ActivePointerImg /> :
                <InactivePointerImg />
              }
              <OhHoverLocationDetails mark={mark} />
            </div>
          </OverlayView>
        )
      })}
    </GoogleMap>
  ) : <></>
}

export default React.memo(MapsComponent)
