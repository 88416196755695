import React from 'react';
import footerSonarLogo from '../../../assests/sonarWebImages/sonarFooter.png';
import linkedinImg from '../../../assests/sonarWebImages/linkedin.svg';
import STRINGS from '../../../helpers/staticContentHelper';
import { useNavigate } from 'react-router-dom';
import { LStorage } from '../../../utils/localStorage.Helper';

const FOOTER_DETAILS = [
    // {
    //     title: 'Company',
    //     links: [
    //         {
    //             name: 'About us',
    //             url: ''
    //         },
    //         {
    //             name: 'Blog',
    //             url: ''
    //         },
    //         {
    //             name: 'Pricing',
    //             url: ''
    //         }
    //     ]
    // },
    {
        title: STRINGS.COMPLIANCE,
        links: [
            {
                name: STRINGS.SECURITY,
                url: STRINGS.SECURITY_LINK
            },
            {
                name: STRINGS.TERMS_AND_CONDITIONS,
                url: STRINGS.TERMS_AND_CONDITIONS_LINK,
                buttonName: STRINGS.TERMS_AND_CONDITIONS_BUTTON_TEXT
            },
            {
                name: STRINGS.PRIVACY_POLICY,
                url: STRINGS.PRIVACY_LINK,
                buttonName: STRINGS.PRIVACY_LINK_BUTTON_TEXT
            }
        ]
    },
    {
        title: STRINGS.CONTACT_US,
        links: [
            {
                name: STRINGS.SONAR_LABS_EMAIL,
                url: ''
            },
            // {
            //     name: '+916366564505',
            //     url: ''
            // },
        ],
        socialMediaLinks: true
    }
]

function FooterSection({ posthog }) {
    const userData = LStorage.getUserData()
    const navigate = useNavigate();

    const postHogRecording = () => {
        if (userData.email) {
            posthog.identify(userData.email)
        }
    }
    return (
        <div className='web-workflow-section-full-width' id='footer_section'>
            <div className='web-footer-container'>
                <h3 className='web-section-title' style={{ color: 'white' }}>
                    {STRINGS.FIND_ANSWERS_TO_YOUR_QUESTIONS}
                </h3>

                <button className='button-info-web'
                    onClick={() => {
                        userData ? postHogRecording()
                            :
                            posthog.capture('signup_clicked_footer')
                        if (userData?.access_token) {
                            navigate('/projects')
                        } else {
                            window.open(
                                'https://cal.com/sonar/15min',
                                '_blank'
                            );
                        }
                    }}>
                    {userData ? "Go to dashboard" : STRINGS.GET_IN_TOUCH}
                </button>

                <hr className='horizontal-doted-line' />

                <div className='footer-content-container'>

                    <div className='footer-logo-content'>
                        <img src={footerSonarLogo} alt='no' height='30px' width='170px' />
                        <p className='footer-company-address'>
                            {STRINGS.ADDRESS}
                        </p>
                    </div>

                    <div className='footer-other-information'>
                        {FOOTER_DETAILS.map(footerData => {
                            return (
                                <div className='footer-logo-content' key={footerData}>
                                    <p className='footer-other-information-title'>
                                        {footerData.title}
                                    </p>
                                    {footerData.links.map(link => {
                                        return (
                                            <a href={link.url} key={link + link.name} onClick={() => {
                                                if (link.buttonName) {
                                                    posthog.capture(link.buttonName)
                                                }
                                            }}>
                                                {link.name}
                                            </a>
                                        )
                                    })}
                                    {footerData.socialMediaLinks &&
                                        <div className='social-media-icons-footer'>
                                            {/* <img src={metaImg} alt='no' />
                                            <img src={xImg} alt='no' /> */}
                                            <img style={{ cursor: 'pointer' }} src={linkedinImg} alt='no' onClick={() => {
                                                window.open('https://www.linkedin.com/company/sonarlabs/')
                                            }} />
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className='footer-content-container' style={{ marginTop: "4rem" }}>
                    <div className='footer-logo-content'>
                        <p style={{ color: 'white' }}>
                            {STRINGS.COPYRIGHT_TEXT}
                        </p>
                    </div>

                    <div className='footer-logo-content'>
                        <p style={{ color: 'white' }}>
                            {STRINGS.CIN}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterSection