import React, { useEffect, useState } from 'react'
import SonarNewLogo from '../../assests/SonarNewLogo.svg'
import UseImg from '../../assests/user.svg'
import NotificationsImg from '../../assests/notifications.svg';
import { Button } from '@mui/material';
import { buttonStyle } from '../../pages/newProjects/components';
import searchImg from '../../assests/search.svg'
import TextField from '@material-ui/core/TextField';
import STRINGS from '../../helpers/staticContentHelper';
import UserProfileImg from '../../assests/userProfile.svg'
// import SettingsImg from '../../assests/settings.svg'
import LogOutImg from '../../assests/logout.svg'
import ShowProfileDetails from '../showProfileDetails';
import { LStorage } from '../../utils/localStorage.Helper';
import { USER_LOG_OUT } from '../../services';
import { usePostHog } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';
import { IS_USER_ADMIN } from '../../utils/encryptionDecryption';
import { decryptResponse } from '../../utils/encryptionDecryption';
import MultiUserImg from '../../assests/multiUser.svg';
import AddUserModal from '../../pages/newProjectDetails/components/modal/addUser.modal';
import { checkUserPermission } from '../../utils/encryptionDecryption';
import { NO_MATERIAL_SEARCH_ACCESS_ORGS } from '../../helpers/staticContentHelper';
const USER_OPTIONS = [
    {
        title: 'Profile',
        icon: UserProfileImg
    },
    {
        title: 'Manage team',
        icon: MultiUserImg,
        requiredPermission: true
    },
    {
        title: 'Logout',
        icon: LogOutImg
    },
]

function ProjectsHeader({ setOpen, tableData }) {
    const [isUserDropDownActive, setIsUserDropDownActive] = useState(false);
    const [openProfileModal, setOpenProfileModal] = useState(false);
    const userData = LStorage.getUserData();
    const posthog = usePostHog()
    const navigate = useNavigate()
    const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

    const logOutUser = () => {
        const reqData = {
            refresh_token: userData.refresh_token
        }

        USER_LOG_OUT(reqData).then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                posthog.reset()
                LStorage.removeUserData()
                navigate('/')
                if (parsedDecryptedData?.url) {
                    window.open(parsedDecryptedData.url, "_blank");
                }
            }
        })
    }

    return (
        <div className='projects-header'>
            <AddUserModal
                open={isInviteUserModalOpen}
                setOpen={setIsInviteUserModalOpen}
                userData={userData} />
            <ShowProfileDetails openFolderModal={openProfileModal} setOpenFolderModal={setOpenProfileModal} />
            <div className='logo-with-user-name'>
                <img src={SonarNewLogo} alt='notfound' className='sonar-logo' onClick={() => navigate("/")} />
                <div className='vertical-line'></div>
                <p> Hello, {userData?.first_name} </p>
            </div>
            <div className='logo-with-user-name'>
                {tableData.length > 0 &&
                    <>
                        {/* <div className='search-input-projects'>
                            <img src={searchImg} alt='not-found' />
                            <TextField
                                // onChange={(event) => handleChange(event, index)}
                                placeholder='Search by project name'
                                InputProps={{
                                    disableUnderline: true,
                                    style: { padding: '0px', width: '250px', fontSize: '14px', paddingTop: '0.2rem' }
                                }}
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            // inputProps={{
                            //     style: { padding: 0 },
                            // }}
                            />
                        </div> */}
                        {checkUserPermission("CREATE", userData) &&
                            <>
                                <Button type='submit' variant="outlined" size="large" fullWidth
                                    sx={{ ...buttonStyle, backgroundColor: '#1B2E31', color: "white" }}
                                    onClick={() => setOpen(true)}
                                >
                                    {STRINGS.CREATE_PROJECT}
                                </Button>
                            </>
                        }
                    </>
                }
                <Button type='submit' variant="outlined" size="large" fullWidth
                    sx={{ ...buttonStyle, backgroundColor: '#1B2E31', color: "white", whiteSpace: "nowrap" }}
                    onClick={() => navigate('/material-search')}
                >
                    {STRINGS.MATERIAL_SEARCH}
                </Button>
                {/* notification component */}
                {/* <div className='nav-bar-icons'>
                    <img src={NotificationsImg} alt='notifications' />
                    <div className='dot-notification'>15</div>
                </div> */}
                <div className='nav-bar-icons'
                    onClick={() => setIsUserDropDownActive(prev => !prev)}>
                    <img src={UseImg} alt='userImg' />
                    {USER_OPTIONS.length > 0 && isUserDropDownActive &&
                        <div className='drop-down-options-list'>
                            {USER_OPTIONS.map((option, index) => {
                                if (option.requiredPermission && !checkUserPermission("INVITE", userData)) {
                                    return <></>
                                }
                                return (
                                    <div className='drop-down-option'
                                        key={index}
                                        onClick={() => {
                                            if (option.title === 'Manage team') {
                                                setIsInviteUserModalOpen(true)
                                            }
                                            if (option.title === 'Profile') {
                                                setOpenProfileModal(true)
                                            }
                                            if (option.title === 'Logout') {
                                                logOutUser()
                                            }
                                        }}>
                                        {option.icon &&
                                            <img src={option.icon} alt='not-found' />
                                        }
                                        {option.title}
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProjectsHeader