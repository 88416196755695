import { USA_SERVER_VALIDATION, getApiEndpoints, IP_INFO_URL } from "./api.endpoints";
import { API_FOR_FORM, handleApiResponse } from "./axios.config";
import { encryptPayload } from "../utils/encryptionDecryption";
import axios from "axios";

const IP_TOKEN = process.env.REACT_APP_IP_INFO_TOKEN

export const USER_SIGN_UP = async (data, setFormData, formData) => {
    const encryptedPayload = encryptPayload(data)
    const locationResponse = await axios.get(`${IP_INFO_URL}${IP_TOKEN}`)
    const clientCountry = locationResponse.data.country
    let instance = false
    if (clientCountry === 'US') {
        instance = true
        setFormData({ ...formData, 'country': 'US' })
    }

    const API_END_POINT = getApiEndpoints(instance);

    console.log(API_END_POINT.USER_SIGN_UP)

    const res = await API_FOR_FORM.post(API_END_POINT.USER_SIGN_UP, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}
export function getBooleanFromLocalStorage(key) {
    const value = localStorage.getItem(key);
    return value === 'true';
}
export const USER_LOG_IN = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.USER_LOG_IN, encryptedPayload).then(res => {
        return { ...res?.data }
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}
export const USER_LOG_OUT = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.LOG_OUT_USER, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const VERIFY_USER = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.VERIFY_USER, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });;
    return res;
}
export const RESEND_VERIFICATION_LINK = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.RESEND_VERIFICATION_LINK, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });;
    return res;
}

export const REFRESH_TOKEN = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.REFRESH_TOKEN, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });;
    return res;
}

export const RESET_PASSWORD_OTP = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.RESET_PASSWORD_OTP, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });;
    return res;
}
export const VERIFY_PASSWORD_OTP = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.VERIFY_PASSWORD_OTP, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });;
    return res;
}

export const RESET_PASSWORD = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.put(API_END_POINT.RESET_PASSWORD, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });;
    return res;
}

export const SERVER_VALIDATION = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const res = await API_FOR_FORM.post(USA_SERVER_VALIDATION, encryptedPayload).then(res => {
        return { ...res?.data }
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const INVITE_USER_TO_ORG = async (data) => {
    console.log(data)
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.post(API_END_POINT.INVITE_USER_TO_ORG, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });;
    return res;
}
export const INVITE_USER_TO_POJECT = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.post(API_END_POINT.INVITE_USER_TO_POJECT, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });;
    return res;
}

export const LIST_ORG_MEMBERS = async (orgId) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.get(API_END_POINT.LIST_ORG_MEMBERS + orgId).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });;
    return res;
}

export const GET_ORG_DETAILS = async () => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.get(API_END_POINT.ORG_DETAILS).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const LIST_PROJECT_MEMBERS = async (data) => {
    const query = `org_id=${data.org_id}&project_id=${data.project_id}&existing_project_users=${data.existing_project_users}`
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.get(API_END_POINT.LIST_PROJECT_MEMBERS + query).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });;
    return res;
}

export const KINDE_LOG_IN = async () => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.post(API_END_POINT.USER_LOG_IN).then(res => {
        return res?.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const KINDE_SIGN_UP = async () => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.post(API_END_POINT.USER_SIGN_UP).then(res => {
        return res?.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const KINDE_AUTH_URL = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API_FOR_FORM.post(API_END_POINT.KINDE_AUTH_URL, encryptedPayload).then(res => {
        console.log(res)
        return res?.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const DELTE_USER_FROM_ORG = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.DELTE_USER_FROM_ORG, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}

export const DELTE_USER_FROM_PROJECT = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.DELTE_USER_FROM_PROJECT, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res;
}