import { Navigate, Outlet } from "react-router-dom";
import NotFound from "../pages/notfound";
import Dashboard from "../pages/dashboard";
import VerifyUser from "../pages/verifyUser";
import Demo from "../pages/demo";
import PrivacyPolicy from "../pages/privacyPolicy";
import TermsOfUse from "../pages/TermsOfUse";
import NewProjects from "../pages/newProjects";
import NewProjectDetails from "../pages/newProjectDetails";
import NewWebPage from "../pages/newWebPage";
import SecurityMain from '../pages/security';
import KindeCallback from "../pages/KindeCallback";
import MaterialLayout from "../pages/materialSearch/materialLayout";
import SearchMaterial from "../pages/materialSearch/searchForMaterial";
import MaterialSearchHistory from "../pages/materialSearch/materialSearchHistory";
import RefundAndCancellationPolicy from "../pages/refundCancelationPolicy";
import ShippingPilicy from "../pages/shippingPolicy";
import ContactUs from "../pages/contactUs";

const routes = (isLoggedIn) => [
    { path: "/", element: <NewWebPage /> },
    { path: "/verify-user/:userToken", element: <VerifyUser /> },
    { path: "/demo", element: <Demo /> },
    { path: "/privacy", element: <PrivacyPolicy /> },
    { path: "/terms-of-use", element: <TermsOfUse /> },
    { path: "/kinde-callback", element: <KindeCallback /> },
    { path: "/security", element: <SecurityMain /> },
    { path: "/refund-and-cancellation-policy", element: <RefundAndCancellationPolicy /> },
    { path: "/shipping-policy", element: <ShippingPilicy /> },
    { path: "/contact-us", element: <ContactUs /> },
    {
        path: "/",
        element: isLoggedIn ? (
            <div>
                {/*REPLACE DIV WITH LAYOUT */}
                <Outlet />
            </div>
        ) : (
            <Navigate to="/" />
        ),
        children: [
            { path: "/search", element: <Dashboard /> },
            { path: "/projects", element: <NewProjects /> },
            { path: "/project-files/:projectId", element: <NewProjectDetails /> },
            {
                path: "/",
                element: <MaterialLayout>
                    <Outlet />
                </MaterialLayout>,
                children: [
                    { path: "/material-search", element: <SearchMaterial /> },
                    { path: "/material-history", element: <MaterialSearchHistory /> },
                ],
            },
        ],
    },
    {
        path: "/",
        element: !isLoggedIn ? <Outlet /> : <Navigate to="/" />,
    },
    { path: "/404", element: <NotFound /> },
    { path: "*", element: <NotFound /> },
];

export default routes;