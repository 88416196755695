import React from 'react';
import { Checkbox } from '@mui/material';
import { MATERIALS_LIST } from '../../../helpers/staticContentHelper';

function CheckboxDropdown({ setApiRequestPayload, apiRequestPayload }) {
    return (
        <div className="grid-container">
            {MATERIALS_LIST.map(category => {
                return (
                    <div className="grid-item" onClick={(e) => {
                        e.stopPropagation()
                        if (apiRequestPayload.materials.includes(category)) {
                            const filteredList = apiRequestPayload.materials.filter(i => i !== category);
                            setApiRequestPayload(prev => {
                                return { ...prev, materials: filteredList }
                            })
                        } else {
                            setApiRequestPayload(prev => {
                                return { ...prev, materials: [...prev.materials, category], isValueChanges: true }
                            })
                        }
                    }}>
                        <Checkbox
                            sx={{
                                color: '#1F5D5E',
                                '&.Mui-checked': {
                                    color: '#1F5D5E',
                                },
                                padding: '0px',
                            }}
                            id="myCheckbox"
                            checked={apiRequestPayload.materials.includes(category)}
                            onChange={() => { }}
                        />
                        <span className="filter-sub-text" for="myCheckbox" style={{ marginLeft: "0.4rem" }}>{category}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default CheckboxDropdown